<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.views.reporting') }}
    </hero-bar>

    <section class="section is-main-section capitalize">
      <b-button
      tag="router-link"
      :to="{ name: 'reporting.create' }"
      type="is-primary"
      icon-left="plus">{{ $getTranslation('form.general.add') }} {{ $getTranslation('general.views.report') }}
    </b-button>
    </section>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced">
          <div v-if="!loading">

            <app-table
              :columns="columns"
              :page="page"
              :total="total"
              :editRoute="editRoute"
              :viewRoute="viewRoute"
              :deleteRoute="endpoint"
              @onPageChange="onPageChange"
              :pagination-top="true"
              :pagination-bottom="true"
            />

          </div>
          <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>
    </section>
  </div>
</template>

<script>
import { ApiReporting } from "@/api-routes.js"
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'app-reporting',
  mixins: [ListTemplateMixin],
  components: {
    'app-table': Table
  },

  data () {
    return {
      endpoint: ApiReporting,
      editRoute: 'reporting.edit',
      viewRoute: 'reporting.view',
      total: 0,
      page: 1,
      // perPage: 15,
      // Table
      columns: {
        'title':'form',
        'survey_instance':'form',
        'status':'form',
        'created_by':'form',
        'created':'general',
        'updated':'general'
      },
      // columns: [
      //   {column: ['title', 'form'], link: false},
      //   {column: ['survey_instance', 'form'], link: 'surveys.detail'},
      //   {column: ['status', 'form'], link: false},
      //   {column: ['created_by', 'form'], link: 'users.edit'},
      //   {column: ['created', 'general'], link: false},
      //   {column: ['updated', 'general'], link: false},
      // ]
    }
  },

  created () {
    this.fetchPaginatedItems ()
    // console.log('Reporting list created', this.editRoute, this.viewRoute)
  }
}
</script>
